import React from "react"
import { FormCheck } from "react-bootstrap"
import useFormField from "./useFormField"

const GenderField = ({ id }) => {
  const { value, setValue } = useFormField(id)

  return (
    <div className="GenderField">
      <FormCheck
        custom
        inline
        label="Chłopiec"
        name={id}
        type={"radio"}
        id="boy"
        onChange={(e) => setValue("boy")}
        checked={value === "boy"}
      />
      <FormCheck
        custom
        inline
        label="Dziewczynka"
        name={id}
        type={"radio"}
        id="girl"
        onChange={(e) => setValue("girl")}
        checked={value === "girl"}
      />
    </div>
  )
}

export default GenderField
