import React from "react"
import { Col, Container, Row, Spinner } from "react-bootstrap"

const Loader = ({ text = "Wczytuję..." }) => {
  return (
    <Container>
      <Row>
        <Col className="d-flex justify-content-center align-items-center p-2">
          <Spinner className="mr-2" animation="border" />
          <p className="m-0">{text}</p>
        </Col>
      </Row>
    </Container>
  )
}

export default Loader
