import React from "react"
import { Card, Container, Table } from "react-bootstrap"
import useFormField from "./fields/useFormField"

import "./ResultsSubscaleTable.scss"

const OBSERVATIONAL_SCALES = {
  PWP: "Czynności poznawcze werbalne/prewerbalne",
  MC: "Mowa czynna",
  MB: "Mowa bierna",
  MM: "Motoryka mała",
  MD: "Motoryka duża",
  N: "Naśladowanie wzrokowo-ruchowe",
  EE: "Ekspresja emocjonalna",
  WS: "Wzajemność społeczna",
  SZR: "Specyficzne zachowania ruchowe",
  SZJ: "Specyficzne zachowania językowe",
}
const CARETAKER_SCALES = {
  PZ: "Problemy w zachowaniu",
  S: "Samoobsługa",
  ZA: "Zachowania adaptacyjne",
}

const ResultsSubscaleTable = ({
  subscalesId,
  developmentAgesId,
  centilesId,
  levelsId,
  name,
  headerName,
  headerValue,
  unit,
  mapping,
}) => {
  const { value: results } = useFormField(subscalesId)
  const { value: developmentAges } = useFormField(developmentAgesId)
  const { value: centiles } = useFormField(centilesId)
  const { value: levels } = useFormField(levelsId)

  return (
    <Container className="ResultsSubscaleTable">
      <Card>
        <Card.Header>
          <Card.Title>{name}</Card.Title>
        </Card.Header>

        <Card.Body>
          <Table hover striped>
            <thead>
              <tr>
                <th />
                <th>Wynik surowy</th>
                <th>Wiek rozwojowy</th>
                <th>Centyl</th>
                <th>Poziom rozwoju / adaptacji</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={5} className="section-header">
                  <h5>Skala wykonaniowo-obserwacyjna</h5>
                </td>
              </tr>
              {Object.entries(OBSERVATIONAL_SCALES).map(([id, description]) => (
                <tr key={id}>
                  <td>{description}</td>
                  <td>{results[id]}</td>
                  <td>{developmentAges[id] ?? ""}</td>
                  <td>{centiles[id]}</td>
                  <td>{levels[id]}</td>
                </tr>
              ))}
              <tr>
                <td colSpan={5} className="section-header">
                  <h5>Raport opiekuna</h5>
                </td>
              </tr>
              {Object.entries(CARETAKER_SCALES).map(([id, description]) => (
                <tr key={id}>
                  <td>{description}</td>
                  <td>{results[id]}</td>
                  <td>{developmentAges[id] ?? ""}</td>
                  <td>{centiles[id]}</td>
                  <td>{levels[id]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default ResultsSubscaleTable
