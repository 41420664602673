import React from "react"
import { Card, Container, Table } from "react-bootstrap"
import useFormField from "./fields/useFormField"

import "./ResultsTable.scss"

const ResultsTable = ({ id, name, headerName, headerValue, unit, mapping }) => {
  const { value } = useFormField(id)

  return (
    <Container className="ResultsTable">
      <Card>
        <Card.Header>
          <Card.Title>{name}</Card.Title>
        </Card.Header>

        <Card.Body>
          <Table hover>
            <thead>
              <tr>
                <th>{headerName}</th>
                {Object.keys(value).map((name, index) => (
                  <th key={index}>{mapping ? mapping[name] : name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {headerValue} [{unit}]
                </td>
                {Object.values(value).map((value, index) => (
                  <td key={index}>{value}</td>
                ))}
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default ResultsTable
