import React, { useCallback, useEffect, useState } from "react"
import {
  Alert,
  Button,
  Col,
  Collapse,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  Spinner,
} from "react-bootstrap"
import FORM_DATA_MANAGER from "../requests/form"
import { useHistory } from "react-router"
import { SELECTORS, SELECTORS_KEY, useKeyStore, useStore } from "../store/store"
import { useEffectOnce } from "react-use"

function KeyForm() {
  const changeKey = useKeyStore(SELECTORS_KEY.FN.changeKey)
  const storedKey = useKeyStore(SELECTORS_KEY.key)
  const unloadData = useStore(SELECTORS.FN.unloadData)
  const history = useHistory()
  const [key, setKey] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  useEffectOnce(() => {
    unloadData()
  })

  const checkKey = useCallback(
    (e) => {
      e.preventDefault()
      setError("")
      setLoading(true)

      FORM_DATA_MANAGER.checkKey(key)
        .then(() => {
          changeKey(key)
        })
        .catch((error) => {
          setLoading(false)
          setError(error.response.data.message)
        })
    },
    [key, changeKey]
  )

  useEffect(() => {
    if (!!storedKey && loading) {
      setLoading(false)
      history.push("/welcome")
    } else if (!loading) {
      changeKey("")
    }
  }, [storedKey, changeKey, loading, history])

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={4}>
          <Form>
            <FormGroup>
              <FormLabel>Wpisz klucz formularza:</FormLabel>
              <FormControl value={key} onChange={(e) => setKey(e.target.value)} />
            </FormGroup>
            <Collapse in={!!error}>
              <div>
                <Alert variant="danger">{error}</Alert>
              </div>
            </Collapse>
            <Button onClick={checkKey} disabled={loading} type="submit">
              {loading ? (
                <>
                  <Spinner
                    className="mr-1"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Sprawdzam klucz
                </>
              ) : (
                "Otwórz formularz"
              )}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default KeyForm
