import React from "react"
import { FormControl, FormGroup, FormLabel, FormText } from "react-bootstrap"
import useFormField from "./useFormField"
import TextareaAutosize from "react-textarea-autosize"

const TextField = ({ id, description, label, as = "input", type = "text", required = true }) => {
  const { value, setValue } = useFormField(id, required)

  return (
    <FormGroup className="TextField">
      {label && <FormLabel>{label}</FormLabel>}
      <FormControl
        id={id}
        type={type}
        as={as}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {description && <FormText>{description}</FormText>}
    </FormGroup>
  )
}

const MinRowsTextareaAutosize = ({ children, ...props }) => (
  <TextareaAutosize minRows={3} {...props}>
    {children}
  </TextareaAutosize>
)

export const TextArea = ({ ...props }) => <TextField as={MinRowsTextareaAutosize} {...props} />

export default TextField
