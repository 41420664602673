import React, { useMemo } from "react"
import DatePicker from "react-datepicker"
import range from "lodash/range"
import useFormField from "./useFormField"
import { Button, Form } from "react-bootstrap"

const DateField = ({ id }) => {
  const { value, setValue } = useFormField(id)

  const dateValue = useMemo(() => {
    if (value === "") {
      setTimeout(() => setValue(new Date()))
      return new Date()
    } else {
      return new Date(value)
    }
  }, [value, setValue])

  return (
    <DatePicker
      className="DateField"
      selected={dateValue}
      onChange={(date) => setValue(date)}
      dateFormat={"dd.MM.yyyy"}
      disabledKeyboardNavigation={true}
      renderCustomHeader={DatePickerHeader}
    />
  )
}

const YEARS = range(1990, new Date().getFullYear() + 1, 1)
const MONTHS = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listopad",
  "Grudzień",
]

const DatePickerHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => (
  <div
    style={{
      margin: 10,
      display: "flex",
      justifyContent: "center",
    }}
  >
    <Button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
      {"<"}
    </Button>
    <Form.Control
      as="select"
      custom
      value={date.getFullYear()}
      onChange={({ target: { value } }) => changeYear(value)}
    >
      {YEARS.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Form.Control>

    <Form.Control
      as="select"
      custom
      value={MONTHS[date.getMonth()]}
      onChange={({ target: { value } }) => changeMonth(MONTHS.indexOf(value))}
    >
      {MONTHS.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Form.Control>

    <Button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
      {">"}
    </Button>
  </div>
)

export default DateField
