import equal from "fast-deep-equal"
import axios from "axios"
import CONFIG from "../config"
import { useKeyStore, useStore } from "../store/store"

export const DATA_TYPES = {
  test: "test",
  report: "report",
}

const DATA_TYPE_MAPPING = {
  test: "test",
  report: "comments",
}

const setIsSaving = useStore.getState().setIsSaving

class FormDataManager {
  active = {}
  current = {}
  queue = {}
  promises = {}
  resolves = {}
  key = ""
  requester = null

  constructor() {
    useKeyStore.subscribe(this._initKey, (s) => s.key)
  }

  _initKey = (key) => {
    this.key = key
    this.requester = axios.create({
      baseURL: CONFIG.url,
      headers: { "pep3-key": this.key },
    })
  }

  checkKey(key) {
    this._initKey(key)
    const request = this.requester.post(`${CONFIG.url}/key`, { key })

    request.catch((error) => {
      this.key = ""
      this.requester = null
    })

    return request
  }

  _post(type, data) {
    return this.requester.post(`${CONFIG.url}/${type}`, data[DATA_TYPE_MAPPING[type]])
  }

  _get(withResults) {
    return this.requester.get(withResults ? `${CONFIG.url}/report` : `${CONFIG.url}/report/test`)
  }

  get() {
    return this._get().then((results) => {
      return results.data.data
    })
  }

  update(type, data, forceSave = false) {
    if (!type) throw new Error("No type given")

    if (!equal(this.current[type], data) || forceSave) {
      this.current[type] = data
      const isActive = this.active[type] === true

      if (isActive) {
        this.queue[type] = data
        if (!this.promises[type]) {
          this.promises[type] = new Promise((resolve) => {
            this.resolves[type] = resolve
          })
        }

        return this.promises[type]
      } else {
        this.active[type] = true
        setIsSaving(true)
        const requestPromise = this._post(type, data)
        requestPromise.then(() => {
          if (this.queue[type]) {
            const newData = this.queue[type]
            delete this.queue[type]
            delete this.promises[type]
            const queueResolve = this.resolves[type]
            this.update(type, newData).then((response) => queueResolve(response))
          } else {
            this.active[type] = false
            setIsSaving(false)
          }
        })

        return requestPromise
      }
    } else {
      return new Promise((res) => res())
    }
  }
}

const FORM_DATA_MANAGER = new FormDataManager()

export default FORM_DATA_MANAGER
