import axios from "axios"
import CONFIG from "../config"
import { useAdminStore } from "../store/admin"

const setIsLoggedIn = useAdminStore.getState().setIsLoggedIn

class AdminDataManager {
  requester = axios.create({
    baseURL: CONFIG.url,
  })

  constructor() {
    const token = this._getToken()
    if (token) {
      this._updateRequester(token)
      this.login().then().catch()
    } else {
      setIsLoggedIn(false)
    }
  }

  _updateRequester = (token) => {
    this.requester = axios.create({
      baseURL: CONFIG.url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  _clearToken = () => {
    localStorage.removeItem("token")
    setIsLoggedIn(false)
    this.requester = axios.create({
      baseURL: CONFIG.url,
    })
  }

  _getToken = () => {
    return localStorage.getItem("token")
  }

  _setToken = (token) => {
    localStorage.setItem("token", token)
    this._updateRequester(token)
  }

  login = (username, password) => {
    return new Promise((resolve, reject) => {
      let [url, data] = ["/auth/refresh", {}]
      if (username && password) {
        url = "/auth/login"
        data = { username, password }
      }

      this.requester
        .post(url, data)
        .then(({ data: { access_token } }) => {
          this._setToken(access_token)
          setIsLoggedIn(true)
          resolve()
        })
        .catch(() => {
          this._clearToken()
          setIsLoggedIn(false)
          reject()
        })
    })
  }

  logout = () => {
    return new Promise((resolve, reject) => {
      this.requester
        .post("/auth/logout")
        .then(() => {
          this._clearToken()
          resolve()
        })
        .catch(() => {
          reject()
        })
    })
  }

  generateKeys = (prefix, count) => {
    return new Promise((resolve, reject) => {
      this.requester
        .post("/admin/keys/create", { prefix, count })
        .then(({ data: { keys } }) => {
          resolve(keys)
        })
        .catch((data) => {
          debugger
          reject()
        })
    })
  }

  downloadData = () => {
    return new Promise((resolve, reject) => {
      this.requester
        .get("/admin/data", {
          responseType: "blob",
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }
}

const ADMIN_DATA_MANAGER = new AdminDataManager()

export default ADMIN_DATA_MANAGER
