import React, { useCallback, useState } from "react"
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Collapse,
  Alert,
  Button,
  Spinner,
  Card,
} from "react-bootstrap"
import useMap from "react-use/lib/useMap"
import ADMIN_DATA_MANAGER from "../../requests/admin"

const KeyGenerator = () => {
  const [data, { set }] = useMap({
    prefix: "",
    count: 1,
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [keysList, setKeysList] = useState([])

  const generateKeys = useCallback(() => {
    setKeysList([])
    setLoading(true)
    setError("")
    ADMIN_DATA_MANAGER.generateKeys(data["prefix"], data["count"])
      .then((keys) => {
        setLoading(false)
        setKeysList(keys)
      })
      .catch(() => {
        setLoading(false)
        setError("Wystąpił błąd podczas generowania kluczy.")
      })
  }, [data])

  return (
    <Container>
      <Card>
        <Card.Header>
          <Card.Title>Wygeneruj nowe klucze</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row className="mb-2">
              <Col md={6}>
                <FormGroup>
                  <FormLabel>Prefiks</FormLabel>
                  <FormControl
                    value={data["prefix"]}
                    onChange={(e) => set("prefix", e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <FormLabel>Liczba kluczy</FormLabel>
                  <FormControl
                    type="number"
                    value={data["count"]}
                    onChange={(e) => set("count", e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Collapse in={!!error}>
                  <div>
                    <Alert variant="danger">{error}</Alert>
                  </div>
                </Collapse>
                <Button onClick={generateKeys} disabled={loading} type="submit">
                  {loading ? (
                    <>
                      <Spinner
                        className="mr-1"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Trwa generowanie...
                    </>
                  ) : (
                    "Wygeneruj klucze"
                  )}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Collapse in={keysList.length > 0}>
                  <div>
                    <h3>Wygenerowane klucze:</h3>
                    <ol>
                      {keysList.map((key) => (
                        <li key={key}>{key}</li>
                      ))}
                    </ol>
                  </div>
                </Collapse>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default KeyGenerator
