import { SELECTORS, useStore } from "../../../store"
import { useCallback, useEffect } from "react"
import { get } from "lodash"

const useFormField = (id, isRequired = true) => {
  const value = useStore((state) => get(state.data, id))
  const setData = useStore(SELECTORS.FN.setData)
  const error = useStore(SELECTORS.error$(id))
  const changeError = useStore(SELECTORS.FN.changeError)
  const clearError = useStore(SELECTORS.FN.clearError)

  useEffect(() => {
    if (!isRequired || value || value === 0) {
      clearError(id)
    } else {
      changeError(id, "Brak wartości")
    }

    return () => {
      clearError(id)
    }
  }, [id, isRequired, value, error, changeError, clearError])

  const setValue = useCallback(
    (value) => {
      setData(id, value)
    },
    [id, setData]
  )

  return {
    value: value ?? "",
    setValue,
  }
}

export default useFormField
