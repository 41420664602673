import create from "zustand"
import { devtools } from "zustand/middleware"

export const SELECTORS_ADMIN = {
  isLoggedIn: (s) => s.isLoggedIn,
  isInitialised: (s) => s.isInitialised,
  FN: {
    setIsLoggedIn: (s) => s.setIsLoggedIn,
  },
}

export const useAdminStore = create(
  devtools((set, get) => ({
    isLoggedIn: false,
    isInitialised: false,
    setIsLoggedIn: (isLoggedIn) => set({ isLoggedIn, isInitialised: true }),
  }))
)
