import React, { useCallback, useState } from "react"
import { Container, Row, Col, Collapse, Alert, Button, Spinner, Card } from "react-bootstrap"
import ADMIN_DATA_MANAGER from "../../requests/admin"
import BootstrapSwal from "../../lib/BootstrapSwal"
import fileDownload from "js-file-download"

const DataDownloader = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const downloadData = useCallback(() => {
    setLoading(true)
    setError("")
    ADMIN_DATA_MANAGER.downloadData()
      .then((data) => {
        setLoading(false)
        BootstrapSwal.fire({
          title: "Dane pobrane",
          text: "Pobieranie pliku powinno się rozpocząć",
          icon: "success",
          confirmButtonText: "Zamknij",
        })
        fileDownload(data, "data.csv")
      })
      .catch(() => {
        setLoading(false)
        setError("Wystąpił błąd podczas pobierania danych.")
      })
  }, [])

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <Card.Header>
              <Card.Title>Pobierz dane z badań wstępnych</Card.Title>
            </Card.Header>
            <Card.Body>
              <Collapse in={!!error}>
                <div>
                  <Alert variant="danger">{error}</Alert>
                </div>
              </Collapse>
              <Button onClick={downloadData} disabled={loading} type="submit" size="lg">
                {loading ? (
                  <>
                    <Spinner
                      className="mr-1"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Trwa generowanie danych...
                  </>
                ) : (
                  "Pobierz dane"
                )}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default DataDownloader
