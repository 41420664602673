import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  Spinner,
} from "react-bootstrap"
import React, { useCallback, useState } from "react"
import useMap from "react-use/lib/useMap"
import ADMIN_DATA_MANAGER from "../../requests/admin"
import { useHistory } from "react-router"
import { useEffectOnce } from "react-use"
import { SELECTORS_ADMIN, useAdminStore } from "../../store/admin"
import Loader from "../Loader"
import { Redirect } from "react-router-dom"

export const Logout = () => {
  const isLoggedIn = useAdminStore(SELECTORS_ADMIN.isLoggedIn)
  const setIsLoggedIn = useAdminStore(SELECTORS_ADMIN.FN.setIsLoggedIn)

  useEffectOnce(() => {
    ADMIN_DATA_MANAGER.logout()
    setIsLoggedIn(false)
  })

  if (isLoggedIn) {
    return <Loader />
  } else {
    return <Redirect to="/admin/login" />
  }
}

const Login = ({ logout, next }) => {
  const [data, { set }] = useMap({
    user: "",
    pass: "",
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const history = useHistory()

  useEffectOnce(() => {
    if (logout) {
      ADMIN_DATA_MANAGER.logout()
        .then(() => history.push(next))
        .catch(() => history.push(next))
    }
  })

  const login = useCallback(() => {
    setLoading(true)
    setError("")
    ADMIN_DATA_MANAGER.login(data["user"], data["pass"])
      .then(() => {
        setLoading(false)
        history.push(next)
      })
      .catch(() => {
        setLoading(false)
        setError("Nieprawidłowe dane logowania")
      })
  }, [data, history, next])

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={4}>
          <Card>
            <Card.Header>
              <Card.Title>Zaloguj się</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form>
                <FormGroup>
                  <FormLabel>Użytkownik</FormLabel>
                  <FormControl value={data["user"]} onChange={(e) => set("user", e.target.value)} />
                </FormGroup>
                <FormGroup>
                  <FormLabel>Hasło</FormLabel>
                  <FormControl
                    type="password"
                    value={data["pass"]}
                    onChange={(e) => set("pass", e.target.value)}
                  />
                </FormGroup>
                <Collapse in={!!error}>
                  <div>
                    <Alert variant="danger">{error}</Alert>
                  </div>
                </Collapse>
                <Button onClick={login} disabled={loading} type="submit">
                  {loading ? (
                    <>
                      <Spinner
                        className="mr-1"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Logowanie...
                    </>
                  ) : (
                    "Zaloguj"
                  )}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Login
