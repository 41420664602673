import React, { useMemo } from "react"
import useFormField from "./useFormField"
import intervalAsString from "../../../lib/intervalAsString"

const DatesIntervalField = ({ from, to }) => {
  const { value: fromDate } = useFormField(from)
  const { value: toDate } = useFormField(to)

  const dateInterval = useMemo(() => {
    if (fromDate && toDate) {
      return intervalAsString(fromDate, toDate)
    } else {
      return "-"
    }
  }, [fromDate, toDate])

  return <div>{dateInterval}</div>
}

export default DatesIntervalField
