import Swal from "sweetalert2"

const BootstrapSwal = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success btn-lg mr-1",
    cancelButton: "btn btn-danger btn-lg",
  },
  buttonsStyling: false,
})

export default BootstrapSwal
