import { Card, Col, Container, Row } from "react-bootstrap"
import { FIELD_TYPES } from "./index"

import "./Section.scss"

const Section = ({ name, content }) => (
  <Container className="Section">
    <Row md={10} lg={8} xl={6} className="section-header justify-content-center">
      <Col md={10} lg={8} xl={6}>
        <Card>
          <Card.Header>
            <Card.Title>{name}</Card.Title>
          </Card.Header>
          <Card.Body>
            {content.map((fieldInfo) => {
              const { id, dataId, type, name } = fieldInfo
              const FieldComponent = FIELD_TYPES[type]
              return (
                <Row className="section-element" key={id}>
                  <Col md={4} className="label">
                    {name}
                  </Col>
                  <Col md={8}>{<FieldComponent dataId={dataId ?? id} {...fieldInfo} />}</Col>
                </Row>
              )
            })}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
)

export default Section
