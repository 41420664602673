import React, { useMemo } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import useFormField from "./fields/useFormField"
import { TextArea } from "./fields/TextField"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import duration from "dayjs/plugin/duration"
import "dayjs/locale/pl"

import "./TestInfo.scss"
import intervalAsString from "../../lib/intervalAsString"

dayjs.locale("pl")
dayjs.extend(localizedFormat)
dayjs.extend(duration)

const READABLE_GENDER = {
  boy: "Chłopiec",
  girl: "Dziewczynka",
}

const TestInfo = ({ dataId, name, comment }) => {
  const { value: test } = useFormField(dataId)

  const dates = useMemo(() => {
    const { examination_date, birth_date } = test

    const examination = dayjs(examination_date)
    const birth = dayjs(birth_date)

    return {
      examination: examination.format("LL"),
      birth: birth.format("LL"),
      age: intervalAsString(birth_date, examination_date),
    }
  }, [test])

  return (
    <Container className="TestInfo">
      <Card>
        <Card.Header>
          <Card.Title>{name}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row className="mb-4">
            <Col md={6}>
              <table>
                <tbody>
                  <tr>
                    <td>Data badania:</td>
                    <td>
                      <strong>{dates.examination}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Data urodzenia:</td>
                    <td>
                      <strong>{dates.birth}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Wiek dziecka:</td>
                    <td>{dates.age}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col md={6}>
              <table>
                <tbody>
                  <tr>
                    <td>Kod dziecka:</td>
                    <td>
                      <strong>{test.child_id}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Płeć:</td>
                    <td>
                      <strong>{READABLE_GENDER[test.gender]}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Zawód diagnosty:</td>
                    <td>
                      <strong>{test.profession}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>Uwagi na temat przebiegu badania</h4>
              <TextArea required={false} {...comment} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default TestInfo
