import { BrowserRouter as Router, Switch, Route, Redirect, Link } from "react-router-dom"

import Form from "./components/Form"
import CONFIG from "./config"
import KeyForm from "./components/KeyForm"
import FORM_DATA_MANAGER from "./requests/form"
import { useHistory, useRouteMatch } from "react-router"
import { useEffect, useRef, useState } from "react"
import { onKeyLoaded, SELECTORS_KEY, useKeyStore, SELECTORS, useStore } from "./store/store"
import { useEffectOnce } from "react-use"
import Loader from "./components/Loader"
import { Nav, Navbar } from "react-bootstrap"
import { ToastProvider, useToasts } from "react-toast-notifications"
import Login, { Logout } from "./components/admin/Login"
import KeyGenerator from "./components/admin/KeyGenerator"
import { SELECTORS_ADMIN, useAdminStore } from "./store/admin"
import DataDownloader from "./components/admin/DataDownloader"

const KeyRequired = ({ children, getResults = false }) => {
  const history = useHistory()
  const key = useKeyStore(SELECTORS_KEY.key)
  const unloadData = useStore(SELECTORS.FN.unloadData)
  const loaded = useStore(SELECTORS.loaded)
  const loadData = useStore(SELECTORS.FN.loadData)

  useEffect(() => {
    if (key && !loaded) {
      FORM_DATA_MANAGER._get(getResults)
        .then(({ data }) => {
          loadData(data.data)
        })
        .catch(() => {
          history.push("/")
        })
    }
  }, [key, loadData, loaded, history, getResults])

  useEffect(() => {
    unloadData()
  }, [unloadData, getResults])

  if (!key) return <Redirect to="/" />
  if (!loaded) return <Loader />

  return <>{children}</>
}

const AuthRequired = ({ children }) => {
  const isInitialised = useAdminStore(SELECTORS_ADMIN.isInitialised)
  const isLoggedIn = useAdminStore(SELECTORS_ADMIN.isLoggedIn)

  if (!isInitialised) return <Loader />
  if (!isLoggedIn) return <Redirect to="/admin/login" />

  return <>{children}</>
}

const FormMenu = () => {
  const key = useKeyStore(SELECTORS_KEY.key)

  return (
    <>
      {key && (
        <Nav.Link
          className="d-flex flex-column align-items-end p-0"
          as={Link}
          to="/"
          active={false}
        >
          <span className="key-info">
            Aktualny klucz: <strong>{key}</strong>
          </span>
          <span className="key-link">Zmień klucz</span>
        </Nav.Link>
      )}
    </>
  )
}

const Navigation = ({ MenuComponent = <FormMenu /> }) => {
  return (
    <Navbar className="Navigation" bg="primary" variant="dark" expand="md">
      <Link to="/">
        <Navbar.Brand>
          <img className="main-logo" src="/img/logo-pep-3-pl-small.svg" alt="PEP-3" />
        </Navbar.Brand>
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav>{MenuComponent}</Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

const Footer = () => {
  return (
    <footer>
      <div className="logos">
        <img src="/img/logo-pep-3-pl.svg" alt="PEP-3-PL" />
        <img className="smaller" src="/img/eduprof.svg" alt="EduProf" />
        <img className="smaller" src="/img/proed.svg" alt="ProEd" />
      </div>
    </footer>
  )
}

function Toaster() {
  const { addToast } = useToasts()
  const isSaving = useStore(SELECTORS.isSaving)
  const initialisedRef = useRef(false)

  useEffect(() => {
    if (!initialisedRef.current) {
      initialisedRef.current = true
      return
    }

    if (isSaving) {
      addToast("Zapisuję...", { appearance: "info" })
    } else {
      addToast("Formularz zapisany", { appearance: "success" })
    }
  }, [isSaving, addToast])

  return ""
}

function FormApp() {
  const key = useKeyStore(SELECTORS_KEY.key)

  return (
    <>
      <Toaster />
      <Navigation />
      <Switch>
        <Route path="/" exact>
          <KeyForm />
        </Route>
        <Route path="/welcome">
          <Redirect to="/test/0" />
        </Route>
        <Route path="/test/:page">
          <KeyRequired getResults={false}>
            <Form
              key="test"
              type="test"
              next="report"
              nextText="Zapisz formularz i przejdź dalej"
              nextAlert="Po zapisaniu formularza nie będziesz mógł wrócić do jego edycji - upewnij się, że wszystkie wprowadzone informacje wprowadzone do formularza są poprawne."
            />
          </KeyRequired>
        </Route>
        <Route path="/report/:page">
          <KeyRequired getResults={true}>
            <Form
              key="report"
              type="report"
              next={`${CONFIG.url}/report/${key}/download`}
              nextText="Zapisz i pobierz raport"
            />
          </KeyRequired>
        </Route>
        <Redirect to="/" />
      </Switch>
    </>
  )
}

function AdminMenu() {
  const isLoggedIn = useAdminStore(SELECTORS_ADMIN.isLoggedIn)

  return (
    <>
      {isLoggedIn && (
        <>
          <Nav.Link
            className="d-flex flex-column align-items-end p-0 mr-4"
            as={Link}
            to="/admin/keys"
            active={false}
          >
            Utwórz klucze
          </Nav.Link>
          <Nav.Link
            className="d-flex flex-column align-items-end p-0 mr-4"
            as={Link}
            to="/admin/results"
            active={false}
          >
            Pobierz dane
          </Nav.Link>
          <Nav.Link
            className="d-flex flex-column align-items-end p-0"
            as={Link}
            to="/admin/logout"
            active={false}
          >
            Wyloguj
          </Nav.Link>
        </>
      )}
    </>
  )
}

function AdminApp() {
  let { url } = useRouteMatch()

  return (
    <>
      <Navigation MenuComponent={<AdminMenu />} />
      <Switch>
        <Route path={`${url}/login`}>
          <Login next={`${url}/keys`} />
        </Route>
        <Route path={`${url}/logout`}>
          <Logout />
        </Route>
        <Route path={`${url}/keys`}>
          <AuthRequired>
            <KeyGenerator />
          </AuthRequired>
        </Route>
        <Route path={`${url}/results`}>
          <AuthRequired>
            <DataDownloader />
          </AuthRequired>
        </Route>
        <Route path="/">
          <Redirect to={`${url}/keys`} />
        </Route>
      </Switch>
    </>
  )
}

function App() {
  const [rehydrated, setRehydrated] = useState(false)

  useEffectOnce(() => {
    onKeyLoaded.then(() => {
      setRehydrated(true)
    })
  })

  if (!rehydrated) {
    return <Loader />
  }

  return (
    <ToastProvider autoDismiss autoDismissTimeout={2000}>
      <div className="App mb-3">
        <Router>
          <Switch>
            <Route path="/admin">
              <AdminApp />
            </Route>
            <Route path="/">
              <FormApp />
            </Route>
          </Switch>
        </Router>
        <Footer />
      </div>
    </ToastProvider>
  )
}

export default App
