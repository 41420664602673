import React, { useMemo } from "react"
import { Card, Container } from "react-bootstrap"
import { useStore } from "../../store"
import { TextArea } from "./fields/TextField"
import ScaleQuestions from "../../data/scale-questions.json"

import "./DevelopmentAreaDetails.scss"

const NON_ADAPTIVE_AREAS = ["EE", "WS", "SZR", "SZJ"]

const ADAPTIVE_POINTS_DESCRIPTIONS = {
  0: "Zadania zaliczone na 0",
  1: "Zadania zaliczone na 1",
  2: "Zadania zaliczone na 2",
}

const NON_ADAPTIVE_POINTS_DESCRIPTIONS = {
  0: "Zachowania znacznie nieodpowiednie w stosunku do wieku",
  1: "Zachowania lekko nieodpowiednie w stosunku do wieku",
  2: "Zachowania odpowiednie do wieku",
}

const DevelopmentAreaDetails = ({ name, scale }) => {
  const { test } = useStore((s) => ({ test: s.data.test, results: s.data.results }))
  const questions = useMemo(() => ScaleQuestions[scale], [scale])
  const splitQuestions = useMemo(() => {
    let splitQuestions = {
      0: [],
      1: [],
      2: [],
    }

    for (const [id, question] of Object.entries(questions)) {
      if (test[id] === "ND") {
        splitQuestions[0].push([id, `ND ${question}`])
      } else {
        splitQuestions[test[id]].push([id, question])
      }
    }

    return splitQuestions
  }, [test, questions])

  return (
    <Container className="DevelopmentAreaDetails mb-5">
      <Card>
        <Card.Header>
          <Card.Title>{name}</Card.Title>
        </Card.Header>
        <Card.Body>
          <AnswerSplit scale={scale} questions={splitQuestions} />

          <TextArea label="Podsumowanie" id={`comments.${scale}`} required={false} />
        </Card.Body>
      </Card>
    </Container>
  )
}

const AnswerSplit = ({ scale, questions }) => {
  const { range, titles } = useMemo(
    () =>
      NON_ADAPTIVE_AREAS.includes(scale)
        ? {
            range: [2, 1, 0],
            titles: NON_ADAPTIVE_POINTS_DESCRIPTIONS,
          }
        : { range: [2, 1, 0], titles: ADAPTIVE_POINTS_DESCRIPTIONS },
    [scale]
  )

  return (
    <table className="AnswerSplit">
      <tbody>
        <tr>
          {range.map((answer) => (
            <td key={answer}>
              <h5 className="text-center">{titles[answer]}</h5>
            </td>
          ))}
        </tr>
        <tr>
          {range.map((answer) => (
            <td key={answer}>
              <h6 className="text-center">
                <strong>{questions[answer].length}</strong>
              </h6>
            </td>
          ))}
        </tr>
        <tr>
          {range.map((answer) => (
            <td key={answer}>
              <ul className="answer-split-list">
                {questions[answer].map(([id, question], index) => (
                  <li key={index}>
                    <span className="question-id">{id}</span> {question}
                  </li>
                ))}
              </ul>
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  )
}

export default DevelopmentAreaDetails
