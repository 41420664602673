import { SELECTORS, useStore } from "../../../store"
import React, { useCallback, useEffect, useRef } from "react"
import { Button, ButtonGroup, FormControl } from "react-bootstrap"
import { get } from "lodash"

import "./Value012Field.scss"
import clsx from "clsx"

export const Value012NDField = ({ id }) => Value012Field({ id, ndAvailable: true })

const Value012Field = ({ id, ndAvailable = false }) => {
  const value = useStore((state) => get(state.data, id))
  const error = useStore(SELECTORS.error$(id))
  const errorsShown = useStore(SELECTORS.errorsShown)
  const setData = useStore(SELECTORS.FN.setData)
  const clearDataKey = useStore(SELECTORS.FN.clearDataKey)
  const changeError = useStore(SELECTORS.FN.changeError)
  const clearError = useStore(SELECTORS.FN.clearError)
  const inputRef = useRef()

  useEffect(() => {
    if ((Number.isInteger(value) && 0 <= value && value <= 2) || (ndAvailable && value === "ND")) {
      if (error) {
        clearError(id)
      }
    } else if (value !== 0 && !value) {
      changeError(id, "Brak wartości")
    } else {
      changeError(id, "Nieprawidłowa wartość")
    }

    return () => {
      clearError(id)
    }
  }, [id, ndAvailable, value, error, changeError, clearError])

  const focusInput = (prev = false) => {
    const { current: currentInput } = inputRef
    if (currentInput) {
      let nextInput, currentRow
      currentRow = prev
        ? currentInput.closest("tr")?.previousElementSibling
        : currentInput.closest("tr")?.nextElementSibling
      while (currentRow && !nextInput) {
        const inputs = currentRow.getElementsByTagName("input")

        for (let input of inputs) {
          if (input.type === "tel") {
            nextInput = input
            break
          }
        }

        currentRow = prev ? currentRow.previousElementSibling : currentRow.nextElementSibling
      }

      if (nextInput) {
        nextInput.focus()
      }
    }
  }

  const setValue = useCallback(
    (newValue) => {
      const intValue = parseInt(newValue)
      if ([0, 1, 2].includes(intValue) || newValue === "") {
        if (isNaN(intValue)) {
          clearDataKey(id)
        } else {
          setData(id, intValue)
        }
      } else if (ndAvailable && (newValue === "n" || newValue === "N" || newValue === "ND")) {
        setData(id, "ND")
      }
    },
    [id, setData, clearDataKey, ndAvailable]
  )

  const keyTyped = useCallback(
    ({ key }) => {
      if (
        (ndAvailable ? ["0", "1", "2", "ArrowDown", "N"] : ["0", "1", "2", "ArrowDown"]).includes(
          key
        )
      ) {
        setTimeout(() => focusInput())
      } else if (["ArrowUp"].includes(key)) {
        setTimeout(() => focusInput(true))
      }
    },
    [ndAvailable]
  )

  return (
    <div className={clsx("Value012Field", { nd: ndAvailable, error: errorsShown && error })}>
      <FormControl
        ref={inputRef}
        type="text"
        value={value ?? ""}
        onFocus={() => inputRef.current.select()}
        onKeyDown={keyTyped}
        onChange={(e) => {
          e.preventDefault()
          setValue(e.target.value)
        }}
      />
      <ButtonGroup size="sm">
        {(ndAvailable ? [0, 1, 2, "ND"] : [0, 1, 2]).map((value) => (
          <Button key={value} onClick={() => setValue(value)} tabIndex="-1">
            {value}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  )
}

export default Value012Field
