import React, { useMemo } from "react"
import { Card, Container, Table } from "react-bootstrap"
import useFormField from "./fields/useFormField"
import developmentProfileData from "../../data/development-profile.json"
import { mapValues } from "lodash"

import "./DevelopmentProfile.scss"

const COLS = ["Wiek", "PWP", "MC", "MB", "MM", "MD", "N", "S"]
const FIELD_WIDTH = 80
const FIELD_HEIGHT = 20
const MAX_AGE = 84

const DevelopmentProfile = ({ dataId, name }) => {
  const { value } = useFormField(dataId)
  const developmentAges = useMemo(() => mapValues(value, (v) => v.toString()), [value])

  const points = useMemo(() => {
    let x = FIELD_WIDTH * 1.5
    let points = ""
    for (let age of Object.values(value)) {
      let currentAge = age === "<12" ? 11 : parseInt(age)
      points += `${x} ${FIELD_HEIGHT / 2 + (MAX_AGE - currentAge) * FIELD_HEIGHT} `

      x += FIELD_WIDTH
    }

    return points
  }, [value])

  return (
    <Container className="DevelopmentProfile">
      <Card>
        <Card.Header>
          <Card.Title>{name}</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="development-profile-table">
            <Table>
              <thead>
                <tr>
                  {COLS.map((col, index) => (
                    <th key={index}>{col}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {developmentProfileData.map((dataRow, colIndex) => {
                  let currentAge

                  return (
                    <tr key={colIndex}>
                      {dataRow.map((value, rowIndex) => {
                        const type = COLS[rowIndex]
                        let selected

                        if (type === "Wiek") {
                          currentAge = value
                        } else {
                          selected = developmentAges[type] === currentAge
                        }

                        return (
                          <td key={rowIndex} className={selected ? "table-primary" : ""}>
                            {value}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
              <caption className="profile-line">
                <svg>
                  <polyline
                    points={points}
                    style={{ fill: "none", stroke: "black", strokeWidth: 3 }}
                  />
                </svg>
              </caption>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default DevelopmentProfile
