import { Card, Col, Container, Row, Table } from "react-bootstrap"
import range from "lodash/range"
import React from "react"

import "./SubscalesTable.scss"
import Value012Field from "./fields/Value012Field"

export const SubscalesTable = ({ name, content }) => {
  return (
    <Container>
      <Card className="SubscalesTable">
        <Card.Header>
          <Card.Title>{name}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Container>
            <Row>
              {content.map((info, index) => {
                const { type } = info
                const SubComponent = SUBCOMPONENTS[type]

                return <SubComponent key={index} {...info} />
              })}
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </Container>
  )
}

const SubscaleColumn = ({ id, name, scale, count }) => {
  return (
    <Col className="SubscaleColumn" md={4}>
      <h4>{name}</h4>
      <div className="points">
        <table className="points-description">
          <tbody>
            {scale.map((description, value) => (
              <tr key={value}>
                <td>
                  <strong>{value}</strong>: {description}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="answers">
        <Table hover>
          <thead>
            <tr>
              <th>Nr</th>
              <th>Punkty</th>
            </tr>
          </thead>
          <tbody>
            {range(count).map((position) => {
              const innerId = `${id}-${position + 1}`

              return (
                <tr key={position}>
                  <td>{position + 1}</td>
                  <td>
                    <Value012Field id={innerId} />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </Col>
  )
}

const SUBCOMPONENTS = {
  subscale_column: SubscaleColumn,
}
