import create from "zustand"
import { persist, devtools } from "zustand/middleware"
import produce from "immer"
import { set as dotSet, unset as dotUnset } from "lodash"

let resolveKeyLoad
export const onKeyLoaded = new Promise((resolve) => {
  resolveKeyLoad = resolve
})

export const SELECTORS = {
  touched: (state) => state.touched,
  loaded: (s) => s.loaded,
  errors: (state) => state.errors,
  error$: (id) => (state) => state.errors[id],
  errorsShown: (s) => s.errorsShown,
  data: (s) => s.data,
  comments: (s) => s.data.comments,
  pages: (s) => s.pages,
  pageNumber: (s) => s.pageNumber,
  initialised: (state) => state.initialised,
  isSaving: (s) => s.isSaving,
  FN: {
    setData: (state) => state.setData,
    clearDataKey: (s) => s.clearDataKey,
    loadData: (state) => state.loadData,
    unloadData: (s) => s.unloadData,
    changeError: (state) => state.changeError,
    clearError: (state) => state.clearError,
    showErrors: (s) => s.showErrors,
    untouch: (state) => state.untouch,
    loadPage: (state) => state.changePage,
    setIsSaving: (s) => s.setIsSaving,
  },
}

export const SELECTORS_KEY = {
  key: (s) => s.key,
  FN: {
    changeKey: (s) => s.changeKey,
  },
}

export const useKeyStore = create(
  persist(
    devtools((set, get) => ({
      key: "",
      changeKey: (key) => set({ key }),
      clearKey: () => set({ key: "" }),
    })),
    {
      name: "key-storage",
      onRehydrateStorage: () => {
        resolveKeyLoad()
      },
    }
  )
)

export const useStore = create(
  devtools((set, get) => ({
    data: {},
    pages: [],
    page: {},
    errors: {},
    errorsShown: false,
    loaded: false,
    initialised: false,
    touched: false,
    pageNumber: 0,
    hasNextPage: false,
    hasPrevPage: false,
    isSaving: false,
    loadPages: (pages) =>
      set((state) => {
        const newPageNumber = state.pageNumber < pages.length ? state.pageNumber : 0

        return {
          pages,
          page: pages[newPageNumber] ?? {},
          pageNumber: newPageNumber,
          errorsShown: false,
          hasNextPage: pages.length - 1 > newPageNumber,
          initialised: true,
        }
      }),
    changePage: (pageNumber) => {
      const { pages } = get()

      if (pageNumber >= 0 && pages.length > pageNumber) {
        set((state) => ({
          page: state.pages[pageNumber],
          pageNumber,
          errorsShown: false,
          hasPrevPage: pageNumber > 0,
          hasNextPage: state.pages.length > pageNumber + 1,
        }))
      }
    },
    clearDataKey: (key) =>
      set((state) =>
        produce(state, (draftState) => {
          dotUnset(draftState, `data.${key}`)
        })
      ),
    setData: (key, value) =>
      set((state) =>
        produce(state, (draftState) => {
          draftState.touched = true
          dotSet(draftState, `data.${key}`, value)
        })
      ),
    loadData: (data) => set({ data, loaded: true }),
    unloadData: () => set({ data: {}, loaded: false }),
    untouch: () => set({ touched: false }),
    changeError: (id, error) =>
      set((state) =>
        produce(state, (draftState) => {
          draftState.errors[id] = error
        })
      ),
    setIsSaving: (isSaving) => set({ isSaving }),
    clearError: (id) =>
      set((state) =>
        produce(state, (draftState) => {
          delete draftState.errors[id]
        })
      ),
    showErrors: () => set({ errorsShown: true }),
  }))
)
