import useFormField from "./useFormField"
import {
  DropdownButton,
  Dropdown,
  FormControl,
  FormGroup,
  FormLabel,
  FormText,
} from "react-bootstrap"
import React from "react"

const DEFAULT_PROFESSIONS = [
  "psycholog",
  "pedagog",
  "pedagog specjalny",
  "logopeda",
  "terapeuta SI",
]

const ProfessionField = ({
  id,
  description,
  label,
  as = "input",
  type = "text",
  required = true,
}) => {
  const { value, setValue } = useFormField(id, required)

  return (
    <FormGroup className="TextField">
      {label && <FormLabel>{label}</FormLabel>}
      <DropdownButton id="dropdown-basic-button" title="Wybierz zawód" className="mb-1">
        {DEFAULT_PROFESSIONS.map((profession) => (
          <Dropdown.Item key={profession} onClick={() => setValue(profession)}>
            {profession}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      <FormControl
        id={id}
        type={type}
        as={as}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {description && <FormText>{description}</FormText>}
    </FormGroup>
  )
}

export default ProfessionField
