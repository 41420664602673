import { Container } from "react-bootstrap"
import { SkillsTable } from "./SkillsTable"
import { SubscalesTable } from "./SubscalesTable"
import Section from "./Section"

import GenderField from "./fields/GenderField"
import DateField from "./fields/DateField"
import DatesIntervalField from "./fields/DatesIntervalField"
import TextField, { TextArea } from "./fields/TextField"
import ResultsTable from "./ResultsTable"
import TestInfo from "./TestInfo"
import DevelopmentProfile from "./DevelopmentProfile"
import Comment from "./Comment"
import DevelopmentAreaDetails from "./DevelopmentAreaDetails"
import ResultsSubscaleTable from "./ResultsSubscaleTable"
import ResultsCollectiveTable from "./ResultsCollectiveTable"
import ProfessionField from "./fields/ProfessionField"

export const FIELD_TYPES = {
  gender: GenderField,
  date: DateField,
  text: TextField,
  profession: ProfessionField,
  comment: TextArea,
  dates_interval: DatesIntervalField,
}

const FORM_PART_COMPONENTS = {
  section: Section,
  skills_table: SkillsTable,
  subscales: SubscalesTable,
  results_table: ResultsTable,
  results_collective: ResultsCollectiveTable,
  results_subscale: ResultsSubscaleTable,
  test_info: TestInfo,
  development_profile: DevelopmentProfile,
  comment: Comment,
  developmentAreaDetails: DevelopmentAreaDetails,
}

export const DEFAULT_FORM_COMPONENT = ({ name }) => <Container>{name}</Container>

export default FORM_PART_COMPONENTS
