import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Card, Container, FormCheck, Table } from "react-bootstrap"
import range from "lodash/range"
import Sticky from "react-sticky-el"

import Value012Field, { Value012NDField } from "./fields/Value012Field"
import useFormField from "./fields/useFormField"
import "./SkillsTable.scss"
import { SELECTORS, useStore } from "../../store"
import clsx from "clsx"

export const SkillsTable = ({ name, content }) => {
  return (
    <Card className="SkillsTable">
      <Card.Header>
        <Card.Title>{name}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Container fluid>
          <Sticky className="header-sticky">
            <Table>
              <thead>
                <tr>
                  <th>Nr</th>
                  <th>Pozycja</th>
                  <th className="skill-type">PWP</th>
                  <th className="skill-type">MC</th>
                  <th className="skill-type">MB</th>
                  <th className="skill-type">MM</th>
                  <th className="skill-type">MD</th>
                  <th className="skill-type">N</th>
                  <th className="skill-type">EE</th>
                  <th className="skill-type">WS</th>
                  <th className="skill-type">SZR</th>
                  <th className="skill-type">SZJ</th>
                </tr>
              </thead>
            </Table>
          </Sticky>
          <Table striped hover>
            <tbody>
              {content.map((info, index) => {
                const { type } = info
                const SubComponent = SUBCOMPONENTS[type]

                return <SubComponent key={index} {...info} />
              })}
            </tbody>
          </Table>
        </Container>
      </Card.Body>
    </Card>
  )
}

const SkillsTableSubheader = ({ name }) => {
  return (
    <>
      <tr className="SkillsTableSubheader">
        <td colSpan="12">{name}</td>
      </tr>
      <tr />
    </>
  )
}

const SkillsTableSkillSide = ({ id, name, column, leftLabel, rightLabel }) => {
  const [valueRequired, setValueRequired] = useState(false)
  const { value, setValue } = useFormField(`${id}-side`, valueRequired)
  const { value: fieldValue } = useFormField(id)
  const error = useStore(SELECTORS.error$(`${id}-side`))
  const errorsShown = useStore(SELECTORS.errorsShown)

  useEffect(() => {
    setValueRequired(fieldValue === 2)
  }, [fieldValue])

  const changeSide = useCallback(
    (side) => {
      setValue(side)
    },
    [setValue]
  )

  const setSideLeft = () => changeSide("left")
  const setSideRight = () => changeSide("right")

  const sideWidget = (
    <div className={clsx("side-widget  d-inline-block", { error: errorsShown && error })}>
      <FormCheck
        custom
        name={`${id}-side`}
        label={leftLabel}
        type="radio"
        id={`${id}-side-left`}
        onChange={setSideLeft}
        checked={value === "left"}
      />
      <FormCheck
        custom
        name={`${id}-side`}
        label={rightLabel}
        type="radio"
        id={`${id}-side-right`}
        onChange={setSideRight}
        checked={value === "right"}
      />
    </div>
  )

  return <SkillsTableSkill id={id} name={name} column={column} customWidget={sideWidget} />
}

const SkillsTableSkillEye = (props) => (
  <SkillsTableSkillSide leftLabel="lewe" rightLabel="prawe" {...props} />
)

const SkillsTableSkillHand = (props) => (
  <SkillsTableSkillSide leftLabel="lewa" rightLabel="prawa" {...props} />
)

const SkillsTableSkillND = ({ ...other }) => SkillsTableSkill({ nd: true, ...other })

const SkillsTableSkill = ({ id, name, column, customWidget, nd = false }) => {
  const idNumber = useMemo(() => id.split(".").slice(-1)[0], [id])

  return (
    <tr className="SkillsTableSkill">
      <td>{idNumber}</td>
      <td>
        <span className="skill-name d-block">{name}</span>
        {customWidget}
      </td>
      {range(10).map((index) =>
        index === column ? (
          <td key={index}>
            {!nd && <Value012Field id={id} />}
            {nd && <Value012NDField id={id} />}
          </td>
        ) : (
          <td key={index} />
        )
      )}
    </tr>
  )
}

const SUBCOMPONENTS = {
  skills_table_subheader: SkillsTableSubheader,
  skills_table_skill: SkillsTableSkill,
  skills_table_skill_nd: SkillsTableSkillND,
  skills_table_skill_eye: SkillsTableSkillEye,
  skills_table_skill_hand: SkillsTableSkillHand,
}
