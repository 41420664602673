import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { SELECTORS, useStore } from "../store"
import shallow from "zustand/shallow"
import { Alert, Button, ButtonGroup, Collapse, Container } from "react-bootstrap"
import isEmpty from "lodash/isEmpty"
import range from "lodash/range"
import get from "lodash/get"
import last from "lodash/last"

import testFormData from "../data/forms/test.json"
import reportFormData from "../data/forms/report.json"

import FORM_PART_COMPONENTS, { DEFAULT_FORM_COMPONENT } from "./form-parts"
import "./Form.scss"
import FORM_DATA_MANAGER, { DATA_TYPES } from "../requests/form"
import { useEffectOnce } from "react-use"
import { useRouteMatch, useParams, useHistory, generatePath } from "react-router"
import Loader from "./Loader"
import { Link } from "react-router-dom"
import clsx from "clsx"
import BootstrapSwal from "../lib/BootstrapSwal"

const FORM_DATA = {
  test: testFormData,
  report: reportFormData,
}

const Form = ({ type, next, nextText, nextAlert }) => {
  let { page } = useParams()
  const match = useRouteMatch()
  const history = useHistory()

  const untouch = useStore(SELECTORS.FN.untouch)
  const touched = useStore(SELECTORS.touched)
  const currentPage = useStore((state) => state.page)
  const savedData = useStore(SELECTORS.data)
  const errors = useStore(SELECTORS.errors)
  const showErrors = useStore(SELECTORS.FN.showErrors)
  const errorsShown = useStore(SELECTORS.errorsShown)
  const touchBlockRef = useRef(null)
  const initialised = useStore(SELECTORS.initialised)
  const loadPages = useStore((state) => state.loadPages)
  const loadPage = useStore(SELECTORS.FN.loadPage)
  const pages = useStore(SELECTORS.pages)
  const pageNumber = useStore(SELECTORS.pageNumber)
  const hasErrors = useMemo(() => !isEmpty(errors), [errors])

  const autoSaver = useRef()

  const { hasNextPage, hasPrevPage } = useStore(
    (state) => ({
      hasNextPage: state.hasNextPage,
      hasPrevPage: state.hasPrevPage,
    }),
    shallow
  )

  useEffectOnce(() => {
    loadPages(FORM_DATA[type])
    if (type === "test" && !isEmpty(savedData.results)) {
      history.push("/report/0")
    }
  })

  useEffect(() => {
    clearTimeout(autoSaver.current)
    autoSaver.current = setTimeout(() => {
      FORM_DATA_MANAGER.update(DATA_TYPES[type], savedData)
    }, 10000)

    return () => {
      clearTimeout(autoSaver.current)
    }
  }, [type, savedData])

  const changePage = useCallback(
    (pageNr) => {
      history.push({
        pathname: generatePath(match.path, { page: pageNr }),
      })
    },
    [history, match.path]
  )

  useEffect(() => {
    const pageNr = parseInt(page)
    if (pageNr < pages.length) {
      loadPage(pageNr)
    } else if (initialised) {
      changePage(0)
    }
  }, [page, pages.length, initialised, loadPage, changePage])

  useEffect(() => {
    if (touched) {
      touchBlockRef.current = (e) => {
        e = e || window.event

        // For IE and Firefox prior to version 4
        if (e) {
          e.returnValue = "W formularzu są niezapisane zmiany. Na pewno zamknąć?"
        }

        // For Safari
        return "W formularzu są niezapisane zmiany. Na pewno zamknąć?"
      }

      window.addEventListener("beforeunload", touchBlockRef.current)
    } else if (touchBlockRef.current) {
      touchBlockRef.current = null
      window.removeEventListener("beforeunload", touchBlockRef.current)
    }

    return () => {
      if (touchBlockRef.current) {
        window.removeEventListener("beforeunload", touchBlockRef.current)
      }
    }
  }, [touched])

  const save = useCallback(
    (force = false) => {
      untouch()
      return FORM_DATA_MANAGER.update(DATA_TYPES[type], savedData, force)
    },
    [type, untouch, savedData]
  )

  const pageChanged = useCallback(
    (e) => {
      save()
      window.scrollTo(0, 0)

      if (hasErrors) {
        e.preventDefault()
        showErrors()
      }
    },
    [hasErrors, save, showErrors]
  )

  const goToNextPage = useCallback(() => {
    save().then(() => {
      if (next.lastIndexOf("http") >= 0) {
        window.location.href = next
      } else {
        history.push(`/${next}/0`)
      }
    })
  }, [history, next, save])

  const checkMissingField = useCallback(() => {
    for (let page of pages) {
      for (let pageElement of page) {
        if (pageElement.content) {
          for (let sectionElement of pageElement.content) {
            if (
              sectionElement.id &&
              sectionElement.type !== "comment" &&
              sectionElement.type !== "dates_interval"
            ) {
              if (sectionElement.type === "subscale_column") {
                for (let count of range(sectionElement.count)) {
                  const id = `${sectionElement.id}-${count + 1}`
                  if (get(savedData, id) === undefined) {
                    return last(id.split("."))
                  }
                }
              } else {
                if (get(savedData, sectionElement.id) === undefined) {
                  return last(sectionElement.id.split("."))
                }

                if (
                  ["skills_table_skill_hand", "skills_table_skill_eye"].includes(
                    sectionElement.type
                  )
                ) {
                  const id = `${sectionElement.id}-side`
                  if (get(savedData, id) === undefined && get(savedData, sectionElement.id) === 2) {
                    return last(id.split("."))
                  }
                }
              }
            }
          }
        }
      }
    }

    return false
  }, [pages, savedData])

  const goNext = useCallback(() => {
    const missingField = checkMissingField()

    if (missingField) {
      BootstrapSwal.fire({
        title: "Błąd formularza",
        text: `Nie wypełniłeś poprawnie wszystkich pól. Sprawdź pole ${missingField}`,
        icon: "error",
        confirmButtonText: "Zamknij",
      })
    } else if (nextAlert) {
      BootstrapSwal.fire({
        title: "Uwaga",
        text: nextAlert,
        icon: "warning",
        confirmButtonText: "Przejdź dalej",
        showCancelButton: true,
        cancelButtonText: "Wróć",
      }).then(({ isConfirmed }) => {
        if (isConfirmed) goToNextPage()
      })
    } else {
      goToNextPage()
    }
  }, [checkMissingField, nextAlert, goToNextPage])

  if (!initialised) return <Loader />

  return (
    <div className="Form">
      <Container>
        <Collapse in={hasErrors && errorsShown} mountOnEnter unmountOnExit>
          <Alert variant="danger">Uzupełnij poprawnie wszystkie pola</Alert>
        </Collapse>
      </Container>

      <div className="content">
        {currentPage?.map((element, index) => {
          const extendedElement = {
            ...element,
            type: element.type ?? element.id,
          }
          const FormPart = FORM_PART_COMPONENTS[extendedElement.type] ?? DEFAULT_FORM_COMPONENT
          return <FormPart key={index} {...extendedElement} />
        })}
      </div>
      <Container>
        <div className="buttons">
          <ButtonGroup className="mr-1" size="lg">
            <Button
              className={clsx({ disabled: !hasPrevPage })}
              as={Link}
              to={hasPrevPage ? `/${type}/${pageNumber - 1}` : ""}
              onClick={pageChanged}
            >
              Wstecz
            </Button>
            {range(pages.length).map((page) => (
              <Button
                as={Link}
                to={`/${type}/${page}`}
                onClick={pageChanged}
                active={page === pageNumber}
                key={page}
              >
                {page + 1}
              </Button>
            ))}
            <Button
              className={clsx({ disabled: !hasNextPage })}
              as={Link}
              to={hasNextPage ? `/${type}/${pageNumber + 1}` : ""}
              onClick={pageChanged}
            >
              Dalej
            </Button>
          </ButtonGroup>
          <ButtonGroup size="lg">
            <Button onClick={() => save(true)}>Zapisz</Button>
            {next && !hasNextPage && (
              <Button disabled={!isEmpty(errors)} onClick={goNext}>
                {nextText}
              </Button>
            )}
          </ButtonGroup>
        </div>
      </Container>
    </div>
  )
}

export default Form
