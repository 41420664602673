import React from "react"
import { Card, Container, Table } from "react-bootstrap"
import useFormField from "./fields/useFormField"

import "./ResultsSubscaleTable.scss"

const ResultsCollectiveTable = ({ aggregatedDevelopmentAgesId, developmentAgesId, name }) => {
  const { value: developmentAges } = useFormField(developmentAgesId)
  const { value: aggregatedDevelopmentAges } = useFormField(aggregatedDevelopmentAgesId)

  return (
    <Container className="ResultsTable ResultsCollectiveTable">
      <Card>
        <Card.Header>
          <Card.Title>{name}</Card.Title>
        </Card.Header>

        <Card.Body>
          <Table hover striped>
            <thead>
              <tr>
                <th>Skale zbiorcze</th>
                <th className="scale">PWP</th>
                <th className="scale">MC</th>
                <th className="scale">MB</th>
                <th className="scale">MM</th>
                <th className="scale">MD</th>
                <th className="scale">N</th>
                <th>
                  Uśredniony wiek
                  <br />
                  rozwojowy
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="main">Komunikacja (K)</td>
                <td>{developmentAges["PWP"]}</td>
                <td>{developmentAges["MC"]}</td>
                <td>{developmentAges["MB"]}</td>
                <td className="empty" />
                <td className="empty" />
                <td className="empty" />
                <td className="main">{aggregatedDevelopmentAges["communication"]}</td>
              </tr>
              <tr>
                <td className="main">Motoryka (M)</td>
                <td className="empty" />
                <td className="empty" />
                <td className="empty" />
                <td>{developmentAges["MM"]}</td>
                <td>{developmentAges["MD"]}</td>
                <td>{developmentAges["N"]}</td>
                <td className="main">{aggregatedDevelopmentAges["motility"]}</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default ResultsCollectiveTable
