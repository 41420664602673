import React from "react"
import { Card, Container } from "react-bootstrap"

import { TextArea } from "./fields/TextField"

const Comment = ({ name, ...other }) => (
  <Container className="Comment">
    <Card>
      <Card.Header>
        <Card.Title>{name}</Card.Title>
      </Card.Header>
      <Card.Body>
        <TextArea required={false} {...other} />
      </Card.Body>
    </Card>
  </Container>
)

export default Comment
